.o-header {
  min-height: 100px;
  .-advi-logo-container {
    width: 200px;
    height: 100px;
    background: url('/img/Logo_background.png') no-repeat center center;
    display: flex;
    justify-content: center;
    .-advi-logo {
      max-width: 70%; } } }
.c-pink-h2 {
  font-weight: 400; }
.swiper-container {
  min-height: 105px;
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: #a5055B; } }

